<script lang="ts">
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import { flyInOnScroll } from "../utils/flyInOnScroll.js";
	import type { Media } from "../../../core/schema/Media.js";
	import DynamicPicture from "./Picture/DynamicPicture.svelte";
	import DynamicVideo from "./Video/DynamicVideo.svelte";
	import { isFileVideo } from "../../../core/utils/isFileVideo.js";
	import { isFileImage } from "../../../core/utils/isFileImage.js";
	import { nbspify } from "../../../core/utils/nbspify.js";
	import StaticVideo from "./Video/StaticVideo.svelte";
	import type { SourceSetQuery } from "../utils/SourceSetQuery.js";

	export let title: string | undefined = undefined;
	export let subTitle: string | undefined = undefined;
	export let uploaded: Media | undefined = undefined;
	export let uploadedMobile: Media | undefined = undefined;
	export let image: string | undefined = undefined;
	export let imageSourcesets: Partial<Record<SourceSetQuery, string>> = {};
	export let video: string | undefined = undefined;

	export let contentClass = "";
	let containerClass = "";
	export { containerClass as class };
	/* eslint-disable svelte/no-at-html-tags */
</script>

<div class="relative z-10 min-h-screen {containerClass}">
	{#if uploaded}
		{#if uploadedMobile}
			{#if isFileImage(uploadedMobile)}
				<DynamicPicture
					bucket="storage"
					image={uploadedMobile}
					width={768}
					height={1080}
					imgClass="!h-full !w-full object-cover"
					class="absolute inset-0 -z-10 md:hidden"
					alt={title}
				/>
			{:else if isFileVideo(uploadedMobile)}
				<DynamicVideo
					autoplay
					video={uploadedMobile}
					videoClass="inset-0 absolute object-cover !h-full !w-full"
					class="!absolute inset-0 -z-10 md:hidden"
					bucket="storage"
				/>
			{/if}
		{/if}
		{#if isFileImage(uploaded)}
			<DynamicPicture
				bucket="storage"
				image={uploaded}
				width={1920}
				height={1080}
				imgClass="!h-full !w-full object-cover"
				class="absolute inset-0 -z-10 {uploadedMobile ? 'hidden md:block' : ''}"
				alt={title}
			/>
		{:else if isFileVideo(uploaded)}
			<DynamicVideo
				autoplay
				video={uploaded}
				videoClass="inset-0 absolute object-cover !h-full !w-full"
				class="!absolute inset-0 -z-10 {uploadedMobile ? 'hidden md:block' : ''}"
				bucket="storage"
			/>
		{/if}
	{:else if image}
		<StaticPicture
			{image}
			width={1920}
			height={1080}
			imgClass="!h-full !w-full object-cover opacity-90 brightness-75"
			class="absolute inset-0 -z-10"
			alt={title ?? ""}
			sourcesets={imageSourcesets}
		/>
	{:else if video}
		<StaticVideo
			{video}
			autoplay
			videoClass="absolute inset-0 -z-10 !h-full !w-full object-cover opacity-90 brightness-75"
		/>
	{/if}
	<div
		class="container {contentClass} flex min-h-[inherit] flex-col justify-between gap-24 pb-[4.375rem] pt-[6.3125rem] lg:flex-row lg:pb-[10.6875rem] lg:pt-[7rem]"
	>
		<div class="flex flex-col justify-between gap-y-24" class:flex-1={!$$slots.beside}>
			{#if title}
				<div class="flex w-full flex-col justify-between">
					<div class="flex flex-col gap-6 lg:gap-10">
						<span
							use:flyInOnScroll
							class="text-secondary w-10/12 text-lg font-semibold leading-[1.136] sm:text-xl lg:w-2/3 lg:text-4xl lg:leading-[5.25rem]"
						>
							{nbspify(title)}
						</span>
						{#if subTitle}
							<span use:flyInOnScroll class="text-secondary lg:text-2lg text-2md">{nbspify(subTitle)}</span>
						{/if}
					</div>
				</div>
			{/if}

			<slot />
		</div>

		{#if $$slots.beside}
			<div use:flyInOnScroll>
				<slot name="beside"></slot>
			</div>
		{/if}
	</div>
</div>
